import React from 'react';
import { compose, Mutation } from "react-apollo";
import { Col } from 'antd';
import {
  ReactiveComponent
} from "@appbaseio/reactivesearch";
import { map, some } from "lodash";

import TransitionGrid from '../../components/TransitionGrid/TransitionGrid';
import DealShowcase from '../../components/DealShowcase/DealShowcase';
import Spinner from '../../components/Spinner/Spinner';

import { TOGGLE_DEAL } from '../../graphql/deals';
import withSelectedDeals from "../../graphql/hoc/withSelectedDeals";

const FilteredDealResult = ({ hits, isLoading, selectedDeals }) => {
  const results = map(hits, ({ _id, _source: deal }) => ({
    id: _id,
    brand_image_url: deal.brand_image_url,
    uid: deal.deal_uid,
    title: deal.title,
    description: deal.description,
    legal: deal.legal,
    isSelected: some(selectedDeals, ['id', _id])
  }));

  const items = map(results, (deal) => (
    <Mutation mutation={TOGGLE_DEAL} variables={{...deal}}>
      {toggleDeal => (
        <DealShowcase toggleDeal={toggleDeal} {...deal} />
      )}
    </Mutation>
  ));

  return (
    <Col className="gutter-row" span={20}>
      {isLoading ? (
        <Spinner />
      ) : (
        <TransitionGrid
          items={items}
          visible={!isLoading}
        />
      )}
    </Col>
  );
}

export default compose(
  withSelectedDeals
)(FilteredDealResult);
