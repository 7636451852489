import React, { Component } from 'react';
import { compose } from 'react-apollo';
import { map, negate, isUndefined } from "lodash";
import styled from "styled-components";
import auth from '../../Auth';

import OfferCard from '../OfferCard/OfferCard';
import TransitionGrid from '../TransitionGrid/TransitionGrid';

const CategoriesTabPane = ({ categories }) => {
  const needsAuthentication = negate(auth.isAuthenticated)();

  const items = map(categories, (category) => (
    <OfferCard category={category} needsAuthentication={needsAuthentication} />
  ));

  return (
    <TransitionGrid
      items={items}
      visible={!isUndefined(categories)}
    />
  );
};

export default compose()(CategoriesTabPane);
