export const locationQuery = ({ lat, lon, distance }) => {
  return {
    nested: {
      path: "stores",
      query: {
        bool: {
          must: [
            {
              geo_distance: {
                distance: `${distance}mi`,
                "stores.location": {
                  lat: lat,
                  lon: lon
                }
              }
            }
          ]
        }
      }
    }
  };
};
