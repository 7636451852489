import React, { Component } from 'react';
import { Drawer, Icon, Empty, notification } from 'antd';
import { compose } from 'react-apollo';
import { debounce, once } from 'lodash';

import withSelectedDeals from "../../graphql/hoc/withSelectedDeals";

import SelectedDealsList from '../SelectedDealsList/SelectedDealsList';
import { StyledButton, SidePanel, Item } from './styles';

import 'antd/es/empty/style/css';
import 'antd/es/icon/style/css';
import 'antd/es/drawer/style/css';
import 'antd/es/notification/style/css';

const key = 'updatable';

const openNotification = () => {
  notification.open({
    key,
    message: 'Sending Your Deals',
    description: '',
  });
  setTimeout(() => {
    notification.open({
      key,
      message: 'Deals Sent!',
      description: 'You should get them messaged to your phone any second now.',
    });
  }, 3000);
};

class SelectedDrawer extends Component {
  state = { visible: false };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  closeDrawer = () => {
    this.setState({
      visible: false,
    });
  };

  togglerContent = () => {
    const { visible } = this.state;
    this.setState({ visible: !visible });
  };

  showDemo = () => {
    const { markDemoSeen } = this.props;
    const debouncedTogglerContent = debounce(this.togglerContent, 1500);

    this.togglerContent();
    markDemoSeen();
    debouncedTogglerContent();
  }

  render() {
    const { selectedDeals, hasSeenDemo } = this.props;
    const atLeastOneDealSelected = selectedDeals.length > 0
    const dealsCount = selectedDeals.length;

    if (atLeastOneDealSelected && !hasSeenDemo) {
      this.showDemo();
    }

    return (
      <Drawer
        handler={
          <StyledButton>
            <Icon
              type={'dollar'}
              style={{
                color: '#fff',
                fontSize: 20,
              }}
            />
            <span>{dealsCount > 0 && dealsCount}</span>
          </StyledButton>
        }
        title="Selected Deals"
        placement="right"
        closable={false}
        width={300}
        onClose={this.togglerContent}
        onHandleClick={this.togglerContent}
        visible={this.state.visible}
        style={{
          zIndex: 999,
        }}
      >
        {atLeastOneDealSelected ? (
            <SelectedDealsList
              visible={this.state.visible}
              selectedDeals={selectedDeals}
              closeDrawer={this.closeDrawer}
              openNotification={openNotification}
            />
          ) : (
            <div>
              <Empty
                description={
                  <span>
                    0 deals selected
                  </span>
                }
              />
            </div>
          )}
      </Drawer>
    );
  }
}

export default compose(withSelectedDeals)(SelectedDrawer);
