import React, { Component } from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { Layout, Menu } from 'antd';
import posed, { PoseGroup } from 'react-pose';

/* Containers */

import Profile from './containers/Profile/Profile';
import SubscriptionFeed from './containers/SubscriptionFeed/SubscriptionFeed';
import ConsumerHomepage from './containers/ConsumerHomepage/ConsumerHomepage';

/* Components */

import Callback from './components/Callback';
import GuardedRoute from './components/GuardedRoute';
import { Nav } from './components/Nav';
import { Content } from './components/Content';

import auth from './Auth';

import 'antd/es/layout/style/css';
import 'antd/es/menu/style/css';

import './styles/global.css';

const RouteContainer = posed.div({
  enter: { opacity: 1, delay: 300, beforeChildren: true },
  exit: { opacity: 0 }
});

const Container = styled(RouteContainer)`
  width: 90%;
  margin: 0 auto;
  height: 100%;
`;

class App extends Component {
  async componentDidMount() {
    if (this.props.location.pathname === '/callback') return;

    try {
      await auth.silentAuth();

      this.forceUpdate();
    } catch (err) {
      if (err.error === 'login_required') return;

      console.log(err.error);
    }
  }

  render() {
    return (
      <Layout>
        <Nav />
        <Content>
          <Route render={({ location }) => (
            <PoseGroup>
              <Container key={location.pathname}>
                <Switch location={location}>
                  <Route exact path="/" component={ConsumerHomepage} key="home" />
                  <Route exact path="/subscribe" component={SubscriptionFeed} key="subscribe" />
                  <GuardedRoute path="/profile" component={Profile} key="profile" />
                  <Route exact path="/callback" component={Callback} key="callback" />
                </Switch>
              </Container>
            </PoseGroup>
          )} />
        </Content>
      </Layout>
    );
  }
}

export default withRouter(App);
