import React from 'react';
import { compose } from 'react-apollo';
import { Route, Switch, Redirect } from 'react-router-dom';
import styled from 'styled-components';

import { Tabs } from 'antd';
import { TabsStyled } from './styles';
import { StickyContainer, Sticky } from 'react-sticky';

import Follows from '../Follows/Follows';
import Orders from '../Orders/Orders';

const TabPane = Tabs.TabPane;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} style={{ ...style, marginTop: '50px', zIndex: 1, background: '#f0f2f5' }} />
    )}
  </Sticky>
);

const Profile = (props) => {
  return (
    <StickyContainer>
      <TabsStyled color="#000000" defaultActiveKey="1" renderTabBar={renderTabBar}>
        <TabPane tab="FOLLOWING" key="1">
          <Follows {...props} />
        </TabPane>
        <TabPane tab="ORDERS" key="2">
          <Orders {...props} />
        </TabPane>
      </TabsStyled>
    </StickyContainer>
  );
}

export default Profile;
