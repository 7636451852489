import styled from 'styled-components';
import posed, { PoseGroup } from 'react-pose'
import { easing } from 'popmotion'
import { colors } from '../../styles';

const animationTimings = {
  gridEnter : 1200,
  gridLeave : 600,
  cardEnter : 400,
  cardLeave : 400,
  cardStagger : 50
};

const GridProps = {
  preEnter: {
    x: -1000,
    opacity: 0
  },
  enter: {
    x: 0,
    opacity: 1,
    delayChildren: animationTimings.gridEnter,
    staggerChildren: 80,
    transition: {
      opacity: {
        duration: animationTimings.gridEnter,
        ease: easing.linear
      },
      x: { type: 'spring' }
    }
  },
  exit: {
    x: 1000,
    opacity: 0,
    delay: 800,
    staggerChildren: 50,
    transition: {
      opacity: {
        duration: animationTimings.gridLeave,
        ease: easing.linear
      },
      x: { type: 'spring' }
    }
  }
}

const Grid = posed.ul(GridProps)

export const StyledGrid = styled(Grid)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 30px;
  width: 100%;
  padding: 1rem;
  margin: 0;
`;

const itemProps = {
  preEnter: {
    y: -100,
    opacity: 0,
    transition: { type: 'spring' }
  },
  enter: {
    y: 0,
    opacity: 1,
    transition: { type: 'spring' },
    display: 'flex',
    justifyContent: 'center'
  },
  exit: {
    y: -100,
    opacity: 0,
    transition: { type: 'spring' }
  }
}

export const Item = posed.li(itemProps)

export const StyledItem = styled(Item)`
  background-color: #3f51b5;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 25rem;
  color: white;
  cursor: pointer;
  position: relative;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
