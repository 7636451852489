import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { colors } from '../../styles';

const FontAwesomeCheck = (props) => <FontAwesomeIcon color={props.color} icon={faCheck} />;

const StyledFontAwesomeCheck = styled(FontAwesomeCheck)`
  opacity: 0.2;
  font-size: ~'calc(1rem + 1vw)';
  color: transparent;
  transition: opacity 0.3s 0.1s ease;
  -webkit-text-stroke: 3px rgba(0, 0, 0, 0.5);

  ${({ isChecked }) =>
    isChecked &&
    css`
      opacity: 1;
      transform: scale(0);
      color: white;
      -webkit-text-stroke: 0;
      animation: ${icon} 0.3s cubic-bezier(1, 0.008, 0.565, 1.65) 0.1s 1 forwards;
    `}
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const stroke = keyframes`
  100% {
    stroke-dashoffset: 0;
  }
`;

const scale = keyframes`
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
`;

const fill = keyframes`
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
`;
const check = keyframes`
  0% {
    width: 1.5em;
    height: 1.5em;
    border-width: 5px;
  }
  10% {
    width: 1.5em;
    height: 1.5em;
    opacity: 0.1;
    background: rgba(93,190,143,0.2);
    border-width: 15px;
  }
  12% {
    width: 1.5em;
    height: 1.5em;
    opacity: 0.4;
    background: rgba(93,190,143,0.1);
    border-width: 0;
  }
  50% {
    width: 2em;
    height: 2em;
    background: rgb(93,190,143);
    border: 0;
    opacity: 0.6;
  }
  100% {
    width: 2em;
    height: 2em;
    background: rgb(93,190,143);
    border: 0;
    opacity: 1;
  }
`;

const icon = keyframes`
  from {
    opacity: 0;
    transform: scale(0.3);
  }
  to {
    opacity: 1;
    transform: scale(1)
  }
`;

const Checkbox = styled.div`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const LabelCheck = styled.span`
  display: inline-block;
  border-radius: 50%;
  border: 5px solid rgba(93,190,143, 0.3);
  background: white;
  vertical-align: middle;
  margin-right: 20px;
  width: 2em;
  height: 2em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border 0.3s ease;

  &:hover {
    border: 5px solid rgba(93,190,143, 0.5);
  }

  ${({ isChecked }) =>
    isChecked &&
    css`
      animation: ${check} 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) forwards;
    `}
`;

const LabelCheckbox = styled.input`
  display: none;
`;

const LabelText = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-36%,-58%);
`;

const DealCheckbox = ({ isChecked, toggleDeal }) => (
  <Checkbox onClick={() => toggleDeal()}>
    <label className="label">
      <LabelText>
        <LabelCheck isChecked={isChecked} >
          <StyledFontAwesomeCheck
            color={isChecked ? colors.white : colors.coupGreen}
            isChecked={isChecked}
          />
        </LabelCheck>
      </LabelText>
    </label>
  </Checkbox>
);

export default DealCheckbox;
