import styled from 'styled-components';
import { colors } from '../../styles';

export const DealSummary = styled.div`
  min-height: 25rem;
`;

export const DealCategoryBox = styled.div``;

export const TopPortionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .img-holder {
    margin-bottom: 16px;

    img {
      width: 70px;
      height: 40px;
      object-fit: contain;
    }
  }
`;

export const MiddlePortionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 40%;
  margin-bottom: ${({ imageAsUrl }) => !!imageAsUrl ? '10px;' : '30px;'}

  .title {
    color: ${colors.darkGrayText};
    font-size: 20px;
    font-weight: 600;

    font-style: bold;
    margin-bottom: 5px;
  }

  .item-or-service {
    color: ${colors.lightGrayText};
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .legal {
    color: ${colors.veryLightGrayText};
    font-size: 12px;
    font-weight: 400;
  }
`;

export const BottomPortionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .barcode {
    padding: 2px;
    background-color: ${colors.white};
    border-radius: 5px;
    height: 75px;
    width: 75px;
    align-self: center;
    z-index: 10;
    margin-right: 20px;
    margin-top: 35px;
  }

  .expiration {
    font-size: 16px;
    letter-spacing: 2px;
    color: ${colors.lightGrayText};
    margin-top: 10px;
    margin-bottom: 32px;

    h4 {
      margin: 0;
      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 2px;
      font-weight: 600;
      color: ${colors.darkGrayText};
    }
  }
`;

export const DealPreviewBox = styled.div`
  width: 18rem;
  min-height: 25rem;
  box-shadow: 0 8px 19px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  color: ${colors.veryLightGrayText};
  text-align: left;
  position: relative;
  overflow: hidden;

  ${props => props.isSelected && `border: 1px solid ${colors.coupGreen};`}

  .white-portion {
    padding: 36px 36px 16px 36px;
    background-color: ${colors.white};
    height: 400px;
    width: 100%;
  }

  .custom-code {
    color: ${colors.veryLightGrayText};
    font-size: 12px;
    font-weight: 400;
  }

  .footer {
    height: 100px;
    width: 100%;
    position: absolute;
    bottom: 0;
    background-color: ${colors.coupGreen};
    color: white;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 16px;

    .branding-holder {
      display: flex;
      align-items: center;
      flex-direction: row;

      img {
        height: 20px;
        width: auto;
        margin-right: 4px;
      }

      span {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
`;
