import { gql } from 'apollo-boost';

export const LIST_ORDERS = gql`
  query ($input: OrderFilterInput) {
    orders(input: $input) {
      entries {
        id
        deal {
          id
          uid
          title
          description
          logoUrl
          generalExpirationDate
        }
        customer {
          name
          phone
        }
      }
    }
  }
`;

export const CREATE_ORDER = gql`
  mutation ($input: CreateOrderInput!) {
    createOrders(input: $input) {
      uids
      errors {key message}
    }
  }
`;
