import styled from 'styled-components';
import posed, { PoseGroup } from 'react-pose'
import { easing } from 'popmotion'
import { colors } from '../../styles';

export const StyledButton = styled.div`
  position: absolute;
  top: 240px;
  background: ${colors.coupGreen};
  width: 48px;
  height: 48px;
  right: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  pointer-events: auto;
  z-index: 0;
  text-align: center;
  font-size: 16px;
  border-radius: 4px 0 0 4px;

  span {
    color: ${colors.white};
    font-size: 11px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-left: 2px;
  }
`;

const sidebarProps = {
  open: {
    x: '0%',
    delayChildren: 300,
    staggerChildren: 60
  },
  closed: {
    delay: 500,
    staggerChildren: 20,
    x: '-100%'
  }
};

const itemProps = {
  open: { opacity: 1, y: 0 },
  closed: { opacity: 0, y: 20 }
};

export const SidePanel = posed.ul(sidebarProps);
const Item = posed.li(itemProps);

export const StyledItem = styled(Item)`
  background-color: #3f51b5;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 7rem;
  margin-bottom: 7px;
  color: white;
  cursor: pointer;
  position: relative;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
