export default {
  coupGreenDarkened: '#24b681',
  coupGreen: '#2CC18B',
  coupGreenLightened: '#55CF96',
  veryLightGrayText: '#ACACAC',
  secondaryLightGray: '#E1E1E1',
  lightGray: '#ECECEC',
  lightGrayText: '#8F9090',
  midGrayText: '#A6A6A6',
  midGray: '#C6C6C6',
  darkGrayText: '#1B1C1C',
  darkGray: '#333333',
  white: '#ffffff',
  offWhite: '#F5F4F9',
  transWhite: 'rgba(255, 255, 255, 0.7)',
  twentyPercentWhite: 'rgba(255, 255, 255, 0.22)',
  tenPercentCoupGreen: 'rgba(44, 193, 139, 0.1)',
};
