import { gql } from 'apollo-boost';

export const LIST_CATEGORIES = gql`
  query AllCategories {
    categories {
      id
      slug
      name
      uid
      isFollowed
      imageUrl
    }
  }
`;

export const GET_CATEGORY = gql`
  query getCategory($uid: String!) {
    category(uid: $uid) {
      id
      name
      slug
      uid
      brands {
        name
        uid
        id
        isFollowed
        imageUrl
      }
    }
  }
`;
