import { gql } from 'apollo-boost';

export const DELETE_FOLLOW = gql`
  mutation ($uid: String, $input: NewFollow) {
    deleteFollow(followUid: $uid, input: $input) {
      follow {
        id
        brand {
          id
        }
        category {
          id
        }
      }
      errors {
        key
        message
      }
    }
  }
`;

export const CREATE_FOLLOW = gql`
  mutation ($input: CreateFollowInput!) {
    createFollows(input: $input) {
      follows {
        id
        brand {
          id
        }
        category {
          id
        }
      }
      errors {key message}
    }
  }
`;

export const GET_FOLLOWED_CATEGORIES = gql`
  {
    followedCategories @client {
      id
    }
  }
`;

export const GET_FOLLOWED_BRANDS = gql`
  {
    followedBrands @client {
      id
    }
  }
`;

export const LIST_FOLLOWS = gql`
  query ($input: FollowFilterInput) {
    follows(input: $input) {
      entries {
        id
        uid
        brand {
          name
          uid
          id
          isFollowed
          imageUrl
        }
        category {
          id
          name
          slug
          uid
          isFollowed
          imageUrl
        }
      }
    }
  }
`;
