import { zip, first, last } from 'lodash';
import { gql } from 'apollo-boost';

import { GET_FOLLOWED_CATEGORIES, GET_FOLLOWED_BRANDS } from '../graphql/follows';

const getFragmentId = ({ id, brand, category }) => {
  return brand === null ? `Category:${category.id}` : `Brand:${brand.id}`
};

const getFragment = ({ id, brand, category }) => {
  let fragment;

  if (brand === null) {
    fragment = gql`fragment isFollowed on Category {
        isFollowed
        id
      }
    `;
  } else {
    fragment = gql`fragment isFollowed on Brand {
        isFollowed
        id
      }
    `;
  };

  return fragment;
};

const getEntity = (cache, fragmentId, fragment) => {
  return cache.readFragment({
    fragment,
    id: fragmentId
  })
};

const updateEntity = (cache, fragmentId, entity) => {
  cache.writeData({
    id: fragmentId,
    data: {
      ...entity,
      isFollowed: !entity.isFollowed
    }
  });;
};

const updateFn = (cache, { data: { deleteFollow } }) => {
  const { follow } = deleteFollow;
  const fragmentId = getFragmentId(follow);
  const fragment = getFragment(follow);
  const entity = getEntity(cache, fragmentId, fragment);

  updateEntity(cache, fragmentId, entity);
};

const updateCategoryFollowCache = (cache, { data: { createFollows } }) => {
  const onlyCategories = createFollows.follows
    .filter(follow => !!follow.category)

  const fragment = gql`
    fragment isFollowed on Category {
      isFollowed
      id
    }
  `;

  const fragmentIds = onlyCategories
    .map(({ category }) => `Category:${category.id}`)

  const categories = fragmentIds
    .map(fragmentId => {
      return cache.readFragment({
        fragment,
        id: fragmentId
      })
    });

  const zipped = zip(fragmentIds, categories)

  zipped.map((item) => {
    let fragmentId = first(item);
    let category = last(item);

    cache.writeData({
      id: fragmentId,
      data: {
        ...category,
        isFollowed: !category.isFollowed
      }
    });
  });

  const { followedCategories } = cache.readQuery({ query: GET_FOLLOWED_CATEGORIES });

  const newFollowedCategories = onlyCategories
    .map(follow => ({
      id: follow.category.id,
      __typename: "FollowedCategory"
    }))

  const data = {
    followedCategories: followedCategories.concat(newFollowedCategories)
  };

  cache.writeQuery({
    query: GET_FOLLOWED_CATEGORIES,
    data
  });
};

const updateBrandFollowCache = (cache, { data: { createFollows } }) => {
  const onlyBrands = createFollows.follows
    .filter(follow => !!follow.brand)

  const fragment = gql`
    fragment isFollowed on Brand {
      isFollowed
      id
    }
  `;

  const fragmentIds = onlyBrands
    .map(({ brand }) => `Brand:${brand.id}`)

  const brands = fragmentIds
    .map(fragmentId => {
      return cache.readFragment({
        fragment,
        id: fragmentId
      })
    });

  const zipped = zip(fragmentIds, brands)

  zipped.map((item) => {
    let fragmentId = first(item);
    let brand = last(item);

    cache.writeData({
      id: fragmentId,
      data: {
        ...brand,
        isFollowed: !brand.isFollowed
      }
    });
  });

  const { followedBrands } = cache.readQuery({ query: GET_FOLLOWED_BRANDS });

  const newFollowedBrands = onlyBrands
    .map(follow => ({
      id: follow.brand.id,
      __typename: "FollowedBrand"
    }))

  const data = {
    followedBrands: followedBrands.concat(newFollowedBrands)
  };

  cache.writeQuery({
    query: GET_FOLLOWED_BRANDS,
    data
  });
};

export {
  updateBrandFollowCache,
  updateCategoryFollowCache,
  updateFn
}
