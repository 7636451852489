import { graphql } from "react-apollo";
import { GET_SELECTED_DEALS } from '../deals';

const withSelectedDeals = graphql(GET_SELECTED_DEALS, {
  props: ({ data }) => ({
    selectedDeals: data.selectedDeals
  })
});

export default withSelectedDeals;
