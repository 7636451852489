import { graphql } from "react-apollo";
import { LIST_BRANDS } from '../brands';

const withBrands = graphql(LIST_BRANDS, {
  props: ({ data }) => ({
    brands: data.brands
  })
});

export default withBrands;
