import React, { Component } from 'react';
import { compose } from 'react-apollo';
import { Drawer, Icon, Empty } from 'antd';
import { map } from 'lodash';
import {
  MultiDataList,
} from "@appbaseio/reactivesearch";

import { StyledCol, StyledButton } from './styles';
import withCategories from "../../graphql/hoc/withCategories";
import withBrands from "../../graphql/hoc/withBrands";

const WIDTH_BREAKPOINT = 1024;

class DealFilterToolbar extends Component {
  state = {
    collapseToDrawer: false,
    drawerVisible: false
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  // Only want to trigger a render if it meets the breakpoint, not every time it's resized.
  handleResize = () => {
    const { collapseToDrawer } = this.state;
    if (window.innerWidth < WIDTH_BREAKPOINT && !collapseToDrawer) {
      this.setState({ collapseToDrawer: true });
    }
    if (window.innerWidth > WIDTH_BREAKPOINT && collapseToDrawer) {
      this.setState({ collapseToDrawer: false });
    }
  };

  togglerContent = () => {
    const { drawerVisible } = this.state;
    this.setState({ drawerVisible: !drawerVisible });
  };


  renderFilters() {
    const { categories, brands } = this.props;
    const { collapseToDrawer } = this.state;
    const categoryData = map(categories, ({ name }) => ({ label: name, value: name }))
    const brandData = map(brands, ({ name }) => ({ label: name, value: name }))

    const styledColProps = collapseToDrawer ? {} : { className: 'gutter-row', span: 4 };

    return (
      <StyledCol {...styledColProps}>
        {categoryData.length > 0 ? (
          <MultiDataList
            className="data-list-container"
            innerClass={{
              title: "text-title",
              input: "text-input",
              label: "list-item",
              checkbox: "list-checkbox-item",
              list: "list"
            }}
            title="Categories"
            componentId="categoryReactor"
            dataField="brand.brand_categories.category_name_raw.raw"
            data={categoryData}
          />
        ) : null}
        {brandData.length > 0 ? (
          <MultiDataList
            className="data-list-container"
            title="Brands"
            innerClass={{
              title: "text-title",
              input: "text-input",
              label: "list-item",
              checkbox: "list-checkbox-item",
              list: "list"
            }}
            componentId="brandReactor"
            dataField="brand.brand_name.raw"
            data={brandData}
          />
        ) : null}
      </StyledCol>
    );
  }

  render() {
    const { collapseToDrawer, drawerVisible } = this.state;
    if (!collapseToDrawer) return (this.renderFilters());
    else {
      return (
        <Drawer
          handler={
            <StyledButton>
              <Icon
                type={'filter'}
                style={{
                  color: '#fff',
                  fontSize: 20,
                }}
              />
            </StyledButton>
          }
          title="Filters"
          placement="left"
          closable={false}
          width={300}
          onClose={this.togglerContent}
          onHandleClick={this.togglerContent}
          visible={drawerVisible}
          style={{
            zIndex: 999,
          }}
        >
          {this.renderFilters()}
        </Drawer>
      );
    }
  }
}

export default compose(
  withCategories,
  withBrands
)(DealFilterToolbar);
