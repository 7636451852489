import { graphql } from "react-apollo";
import { LIST_CATEGORIES } from '../categories';

const withCategories = graphql(LIST_CATEGORIES, {
  props: ({ data }) => ({
    categories: data.categories
  })
});

export default withCategories;
