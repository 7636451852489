import React, { Component } from "react";
import { compose } from 'react-apollo';
import { map, isUndefined } from "lodash";
import {
  SelectedFilters,
  ReactiveBase,
  ReactiveComponent,
  DataController,
} from "@appbaseio/reactivesearch";
import { Row } from 'antd';

import { ES_URL, ES_USERNAME, ES_PASSWORD, ES_APP } from '../../config/environment';
import { fromLatLng, fromAddress, findZipFromResponse } from '../../lib/geoConversions';
import { locationQuery } from '../../lib/locationQuery';

import DealFilterToolbar from '../DealFilterToolbar/DealFilterToolbar';
import FilteredDealResult from '../FilteredDealResult/FilteredDealResult';

import SelectedDrawer from '../../components/SelectedDrawer/SelectedDrawer';

import { ConsumerPageStyled, GridItem } from './styles';

class ConsumerHomepage extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      location: {
        lat: null,
        lon: null,
        distance: 100
      },
      hasSeenDemo: false
    };
  }

  componentDidMount() {
    this.useCurrentLocation();

    this.setState({
      loading: false
    })
  }

  markDemoSeen = () => {
    this.setState({
      hasSeenDemo: true
    })
  };

  handleDistance = value => {
    this.setState({
      location: {
        ...this.state.location,
        distance: value
      }
    });
  };

  handleZip = event => {
    const zip = event.target.value;

    this.setState({
      location: {
        ...this.state.location,
        zip: zip
      }
    });

    fromAddress(zip, response => {
      const { lat, lng } = response.results[0].geometry.location;
      this.setState({
        location: {
          ...this.state.location,
          lat: lat,
          lon: lng
        }
      });
    });
  };

  useCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(position => {
      const lat = position.coords.latitude;
      const lon = position.coords.longitude;
      fromLatLng(lat, lon, response => {
        const zip = findZipFromResponse(response);

        this.setState({
          location: {
            ...this.state.location,
            lat: lat,
            lon: lon,
            zip
          }
        });
      });
    });
  };

  render() {
    const { location, hasSeenDemo, loading } = this.state;

    return (
      <ConsumerPageStyled>
        <SelectedDrawer hasSeenDemo={hasSeenDemo} markDemoSeen={this.markDemoSeen} />
        <Consumer>
          <DataController
            componentId="locationReactor"
            customQuery={value => locationQuery(value)}
            defaultSelected={location}
          />
          <Row>
            <DealFilterToolbar location={this.state.location} />
            <ReactiveComponent
              componentId="storeQueryResult"
              react={{
                and: ["categoryReactor", "brandReactor", "locationReactor"]
              }}
            >
              <FilteredDealResult />
            </ReactiveComponent>
          </Row>
        </Consumer>
      </ConsumerPageStyled>
    );
  }
}

const Consumer = ({ children }) => {
  if (isUndefined(ES_PASSWORD) || isUndefined(ES_USERNAME)) {
    return (
      <ReactiveBase app={ES_APP} url={ES_URL}>{children}</ReactiveBase>
    )
  }
  return (
    <ReactiveBase app={ES_APP} url={ES_URL} credentials={`${ES_USERNAME}:${ES_PASSWORD}`}>{children}</ReactiveBase>
  );
};

export default compose()(ConsumerHomepage);
