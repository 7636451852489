import React from 'react';
import { Mutation } from 'react-apollo';
import { notification } from 'antd';
import { isUndefined } from 'lodash';
import { OfferCardStyled } from './styles';
import { circleC } from '../../assets/images';

import { getOfferCardMutationParams } from '../../lib/getOfferCardMutationParams';

import 'antd/es/notification/style/css';

const openNotification = (message, description, placement, duration) => {
  notification.warn({
    placement,
    duration,
    message,
    description,
  });
};

const OfferCard = ({ needsAuthentication, ...props}) => {
  const result = getOfferCardMutationParams(props);
  const { input, mutation, update } = result;

  return (
    <Mutation
      mutation={mutation}
      variables={{ input }}
      update={update}
    >
      {mutateAction => {
        const entityType = isUndefined(props.brand) ? 'category' : 'brand';
        const entity = entityType === 'category' ? props.category : props.brand;
        const image = entityType === 'category' ? circleC : entity.imageUrl;

        const color = entity.isFollowed ? "primary" : "secondary";
        return (
          <OfferCardStyled
            brand={entity}
            isFollowed={entity.isFollowed}
            disabled={needsAuthentication}
            label="Get deals for this"
            onClick={needsAuthentication ? () => openNotification(
              'Please Login',
              `Before being able to subscribe to a ${entityType}, you must login.`,
              'topLeft',
              5
            ) : mutateAction}
          >
            <img src={image} />
            <div>{entity.name}</div>
          </OfferCardStyled>
        );
      }}
    </Mutation>
  );
};

export default OfferCard;
