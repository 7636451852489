import React from 'react';
import { SpinContainer, StyledSpinner } from './styles';

const Spinner = () => (
  <SpinContainer>
    <StyledSpinner>
      <div />
      <div />
      <div />
    </StyledSpinner>
  </SpinContainer>
);

export default Spinner;
