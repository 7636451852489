import styled from 'styled-components';
import posed, { PoseGroup } from 'react-pose'
import { easing } from 'popmotion'

const sidebarProps = {
  open: {
    x: '0%',
    delayChildren: 300,
    staggerChildren: 60
  },
  closed: {
    delay: 500,
    staggerChildren: 20,
    x: '-100%'
  }
};

const itemProps = {
  open: { opacity: 1, y: 0 },
  closed: { opacity: 0, y: 20 }
};

export const SidePanel = posed.ul(sidebarProps);
export const Item = posed.li(itemProps);

export const SelectedDealsWrapper = styled(SidePanel)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 575px;
  margin-bottom: 30px;
`;

export const CreateOrderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;
