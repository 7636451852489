import React, { Component } from 'react'
import { PoseGroup } from 'react-pose'
import { StyledGrid, Item } from './styles';

import Spinner from '../Spinner/Spinner';

const TransitionGrid = ({ visible, items }) => {
  return visible ? (
    <PoseGroup preEnterPose="preEnter">
      {visible && (
        <StyledGrid key="grid">
          <PoseGroup preEnterPose="preEnter">
            {items.map((item, idx) => (
              <Item key={idx}>{item}</Item>
            ))}
          </PoseGroup>
        </StyledGrid>
      )}
    </PoseGroup>
  ) : (
    <Spinner />
  )
}

export default TransitionGrid;
