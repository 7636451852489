import React from 'react';
import { Mutation } from 'react-apollo';
import { notification } from 'antd';
import { map, negate } from 'lodash';
import { Button } from '@auth0/cosmos';

import auth from '../../Auth';

import { CREATE_ORDER } from '../../graphql/orders';

const CreateOrder = ({ selectedDeals, closeDrawer, openNotification }) => {
  const needsAuthentication = negate(auth.isAuthenticated)();

  const input = {
    orders: map(selectedDeals, deal => ({
      dealUid: deal.uid
    }))
  };

  return (
    <Mutation
      mutation={CREATE_ORDER}
      variables={{ input }}
      onCompleted={data => {
        closeDrawer();
        openNotification();
      }}
      onError={error => {
        console.log(error.message.substr(15));
      }}
    >
      {(createOrder, { data }) => {
        return (
          <Button label="Get deals" disabled={needsAuthentication} onClick={createOrder}>
            Get My Deals
          </Button>
        );
      }}
    </Mutation>
  );
};

export default CreateOrder;
