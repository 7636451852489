import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';
import { defaults, resolvers } from './resolvers';
import { CLICK_FARM_URL } from './config/environment';

import auth from './Auth';
import App from './App';

const typeDefs = `
  type Deal {
    isSelected: Boolean
  }

  type Mutation {
    toggleDeal(id: ID!): Deal
  }

  type Query {
    selectedDeals: [Deal]
  }
`;

const client = new ApolloClient({
  uri: CLICK_FARM_URL,
  connectToDevTools: true,
  clientState: {
    defaults,
    resolvers,
    typeDefs,
  },
  request: async operation => {
    const token = await auth.getIdToken();

    if (typeof token !== 'undefined') {
      operation.setContext(context => ({
        headers: {
          ...context.headers,
          authorization: `Bearer ${token}`,
        },
      }));
    }
  },
});

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);
