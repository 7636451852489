import styled from 'styled-components';
import { Button } from 'antd';
import { colors } from '../../styles';

export const Container = styled.div`
  width: 100%;
`;

export const StyledButton = styled(Button)`
  &.ant-btn {
    padding-top: 10px;
    padding-bottom: 30px;
    border-radius: 5px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    background-color: ${colors.coupGreen};

    span {
      font-weight: 500;
      color: ${colors.white};
      text-transform: uppercase;
    }

    &:hover {
      border-color: ${colors.coupGreen};
      color: ${colors.coupGreen};
    }
  }

`;
