import gql from 'graphql-tag';
import { map, find, reject, concat } from 'lodash';

const defaults = {
  selectedDeals: [],
  followedCategories: [],
  followedBrands: [],
};

const resolvers = {
  Mutation: {
    toggleDeal: (_, variables, { cache, getCacheKey }) => {
      const query = gql`
        {
          selectedDeals @client {
            id
            uid
            title
            description
            brand_image_url
          }
        }
      `;

      const { selectedDeals } = cache.readQuery({ query });

      const selected = !!find(
        selectedDeals,
        d => d.id === variables.id
      );

      const data = {
        selectedDeals: selected
          ? reject(selectedDeals, d => d.id === variables.id)
          : concat(selectedDeals, {
            id: variables.id,
            uid: variables.uid,
            title: variables.title,
            description: variables.description,
            brand_image_url: variables.brand_image_url,
            __typename: "SelectedDeal"
          })
      };

      cache.writeData({ data });

      return data;
    }
  }
};

export {
  defaults,
  resolvers,
};
