import styled from 'styled-components';
import { colors } from '../../styles';

export const ConsumerPageStyled = styled.div`
  padding: 3em 0 0 0;

  .search-input {
    border-radius: 4px;
    font-size: 18px;
    padding-left: 36px;
  }

  svg.search-icon {
    margin-bottom: 3px;
    fill: ${colors.coupGreen} !important;
    height: 18px;
  }
`;

export const GridItem = styled.div`
  cursor: pointer;
  border-radius: 16px;
  transition: box-shadow .2s;
  width: 322px;
  justify-self: center;

  &:hover {
    box-shadow: 0 10px 22px 0 rgba(0, 0, 0, 0.18);
  }
`;
