import React, { Component } from 'react';
import { Card, Avatar } from 'antd';
import { map } from 'lodash';

import CreateOrder from '../CreateOrder/CreateOrder';

import { SelectedDealsWrapper, CreateOrderWrapper, SidePanel, Item } from './styles';

import 'antd/es/avatar/style/css';
import 'antd/es/card/style/css';

const { Meta } = Card;

const SelectedDealsList = ({ visible, closeDrawer, selectedDeals, openNotification }) => (
  <div>
    <SelectedDealsWrapper pose={visible ? 'open' : 'closed'}>
      {map(selectedDeals, ({ title, description, brand_image_url }) => (
        <Item>
          <Card style={{ marginBottom: '20px' }}>
            <Meta
              avatar={<Avatar src={brand_image_url} />}
              title={title}
              description={description}
            />
          </Card>
        </Item>
      ))}
    </SelectedDealsWrapper>
    <CreateOrderWrapper>
      <CreateOrder
        selectedDeals={selectedDeals}
        closeDrawer={closeDrawer}
        openNotification={openNotification}
      />
    </CreateOrderWrapper>
  </div>
);

export default SelectedDealsList
