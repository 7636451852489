import { gql } from 'apollo-boost';

export const TOGGLE_DEAL = gql`
  mutation ToggleDeal($id: String!, $uid: String!, $title: String!, $description: String!, $brand_image_url: String!) {
    toggleDeal(id: $id, uid: $uid, title: $title, description: $description, brand_image_url: $brand_image_url) @client
  }
`;

export const GET_SELECTED_DEALS = gql`
  {
    selectedDeals @client {
      id
      uid
      title
      description
      brand_image_url
    }
  }
`;
