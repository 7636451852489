import { isUndefined, get, reduce, concat } from 'lodash';
import { updateCategoryFollowCache, updateBrandFollowCache, updateFn } from './updateFollowCache';
import { CREATE_FOLLOW, DELETE_FOLLOW } from '../graphql/follows';

const getDeleteFollowInput = ({ category, brand }) => {
  const input = isUndefined(brand) ? {
    categoryUid: category.uid
  } : {
    brandUid: brand.uid
  };

  return input;
};

const getCreateFollowInput = (props) => {
  return reduce(props, (acc, { uid }, key) => {
    const newKey = `${key}Uid`;
    const result = {
      [newKey]: uid
    };

    return {
      ...acc,
      follows: concat(acc.follows, result)
    }

  }, { follows: [] })
};

const getInput = (props) => {
  const isFollowed = getIsFollowed(props);

  return isFollowed ? getDeleteFollowInput(props) : getCreateFollowInput(props);
}

const getIsFollowed = (props) => {
  const result = reduce(props, (acc, { isFollowed }, key) => {
    return isFollowed;
  }, false);

  return result;
}

const getMutation = (props) => {
  const isFollowed = getIsFollowed(props);
  return isFollowed ? DELETE_FOLLOW : CREATE_FOLLOW
};

const getUpdate = (props) => {
  const isFollowed = getIsFollowed(props);

  if (isUndefined(get(props, 'brand'))) {
    return isFollowed ? updateFn: updateCategoryFollowCache
  }

  return isFollowed ? updateFn: updateBrandFollowCache
};

const getOfferCardMutationParams = (props) => {
  const input = getInput(props);
  const mutation = getMutation(props);
  const update = getUpdate(props);

  return {
    input,
    mutation,
    update
  }
};

export {
  getOfferCardMutationParams
}
