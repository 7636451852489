import React from 'react';
import { Link } from 'react-router-dom';
import { compose } from "react-apollo";
import { Tabs, BackTop } from 'antd';
import { StickyContainer, Sticky } from 'react-sticky';
import { TabsStyled } from './styles';

import withCategories from "../../graphql/hoc/withCategories";
import withBrands from "../../graphql/hoc/withBrands";

import BrandsTabPane from '../../components/BrandsTabPane/BrandsTabPane';
import CategoriesTabPane from '../../components/CategoriesTabPane/CategoriesTabPane';

const TabPane = Tabs.TabPane;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} style={{ ...style, marginTop: '50px', zIndex: 1, background: '#f0f2f5' }} />
    )}
  </Sticky>
);

const SubscriptionFeed = ({ categories, brands }) => {
  return (
    <StickyContainer>
      <TabsStyled color="#000000" defaultActiveKey="1">
        <TabPane tab="CATEGORIES" key="1">
          <CategoriesTabPane categories={categories} />
        </TabPane>
        <TabPane tab="BRANDS" key="2">
          <BrandsTabPane brands={brands} />
        </TabPane>
      </TabsStyled>
      <BackTop />
    </StickyContainer>
  );
}

export default compose(
  withCategories,
  withBrands
)(SubscriptionFeed);
