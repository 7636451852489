import React from 'react';
import { coupLogoWhite } from '../../assets/images';
import { DealSummary, DealCategoryBox, DealPreviewBox, TopPortionContainer, MiddlePortionContainer, BottomPortionContainer } from './styles';
import QRCode from 'qrcode.react';
import moment from 'moment';
import { isNull } from 'lodash';

import DealCheckbox from '../DealCheckbox/DealCheckbox';

const TopPortion = ({ brand_image_url, handleImageUpload }) => (
  <TopPortionContainer>
    <div className="img-holder">
      <img src={brand_image_url} />
    </div>
  </TopPortionContainer>
);


const MiddlePortion = ({ brand_image_url, title, description, legal }) => (
  <MiddlePortionContainer imageAsUrl={brand_image_url}>
    <div className="title" id="title">
      {title}
    </div>
    <div className="item-or-service">{description}</div>
    <div className="legal">{legal}</div>
  </MiddlePortionContainer>
);

const BottomPortion = ({ generalExpirationDate }) => (
  <BottomPortionContainer>
    <div className="barcode">
      <QRCode
        style={{ marginTop: '5px', marginLeft: '5px' }}
        value={''}
        size={70}
        bgColor={'#ffffff'}
        fgColor={'#000000'}
        level={'L'}
      />
    </div>
    <div className="expiration">
      <h4>Expires</h4>
      {!isNull(generalExpirationDate)
        ? moment(generalExpirationDate).format('YYYY-MM-DD')
        : 'Never'}
    </div>
  </BottomPortionContainer>
);

const DealShowcase = ({ isSelected, pathname, toggleDeal, ...rest }) => (
  <DealSummary>
    {!!pathname && pathname === '/profile' ? null : <DealCheckbox toggleDeal={toggleDeal} isChecked={isSelected} />}
    <DealPreviewBox isSelected={isSelected} onClick={toggleDeal}>
      <div className="white-portion">
        <TopPortion {...rest}/>
        <MiddlePortion {...rest} />
        <BottomPortion {...rest} />
      </div>
      <div className="footer">
        <div className="branding-holder">
          <img src={coupLogoWhite} />
          <span> • Deals</span>
        </div>
      </div>
    </DealPreviewBox>
  </DealSummary>
);

export default DealShowcase;
