import React from 'react';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Empty } from 'antd';
import { isNull, map, isUndefined } from 'lodash';

import OfferCard from '../../components/OfferCard/OfferCard';
import TransitionGrid from '../../components/TransitionGrid/TransitionGrid';
import Spinner from '../../components/Spinner/Spinner';
import { LIST_FOLLOWS } from '../../graphql/follows';

import { StyledButton } from './styles';

import 'antd/es/empty/style/css';

const ListFollows = ({ input, history }) => (
  <Query
    query={LIST_FOLLOWS}
    fetchPolicy='network-only'
    variables={{ input }}
  >
    {({ loading, error, data: { follows } }) => {
      if (loading) return <Spinner />;
      if (error) return `Error! ${error.message}`;

      const { entries } = follows;

      const items = map(entries, ({ id, brand, category }) => {
        return isNull(brand) ? (
          <OfferCard category={category} />
        ) : (
          <OfferCard brand={brand} />
        );
      });

      return items.length ? (
        <TransitionGrid
          items={items}
          visible={true}
        />
      ) : (
        <Empty
          image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
          description={
            <span>
              Looks like you haven't started following any brands...
            </span>
          }
        >
          <StyledButton onClick={() => history.replace('/subscribe')}>
            Get You Some
          </StyledButton>
        </Empty>
      );
    }}
  </Query>
);

export default withRouter(ListFollows);
