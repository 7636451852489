import { gql } from 'apollo-boost';

export const LIST_BRANDS = gql`
  query AllBrands {
    brands {
      id
      name
      uid
      isFollowed
      imageUrl
    }
  }
`;
