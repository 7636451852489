import styled from 'styled-components';
import colors from '../../styles/colors';
import { Tabs } from 'antd';

export const TabsStyled = styled(Tabs)`
  margin-top: 50px;
  margin-right: 10px;

  &&& .ant-tabs-tab:hover {
    color: ${colors.coupGreen};
  }

  &&& .ant-tabs-tab-active {
    color: ${colors.coupGreen};
  }

  &&& .ant-tabs-ink-bar {
    background-color: ${colors.coupGreen};
  }
`
