import styled from 'styled-components';
import { Col } from 'antd';
import { colors } from '../../styles';

const item = {
	width: '15px',
	height: '15px',
	scale: '4px',
};

// https://opensource.appbase.io/reactive-manual/theming/class.html
// https://opensource.appbase.io/reactive-manual/list-components/multilist.html
export const StyledCol = styled(Col)`
  .data-list-container {
    padding: 8px;
  }

  .text-title {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing 1.2px;
    color: ${colors.darkGrayText};
    margin-bottom: 0;
  }

  .text-input {
    box-shadow: 0 8px 19px 0 rgba(0, 0, 0, 0.15);
    padding: 8px 12px;
    border: 0px solid;
    border-radius: 4px;
    margin-bottom: 12px;
  }

  .list-checkbox-item {
  	+ label {
  		&::before,
  		&::after {
  			border-radius: 20px;
  		}

      &::after {
        background-color: transparent;
        top: 50%;
        left: calc(1px + ${item.width} / 5);
        width: calc(${item.width} / 2);
        height: calc(${item.width} / 5);
        margin-top: calc(${item.height} / -2 / 2 * 0.8);
        border-style: solid;
        border-color: ${colors.coupGreen};
        border-width: 0 0 2px 2px;
        border-radius: 0;
        border-image: none;
        transform: rotate(-45deg) scale(0);
        transition: none;
      }
    }

    &:checked {
      + label {
        &::before {
          border-color: ${colors.coupGreen};
          &::hover {
            outline: none;
          }
        }

        &::after {
          content: '';
          transform: rotate(-45deg) scale(1);
          transition: transform 200ms ease-out;
        }
      }
    }
  }

  .list-item {
    color: ${colors.darkGrayText};
    font-weight: 400;
    text-transform: uppercase;
    font-size: 16px;

    &:checked + label::before {
        border-color: purple;
    }

    &:checked + label::before,
    &:checked + label::after {
        border-radius: 20px;
    }
  }

  .list {
    .list-item {
      text-transform: capitalize;
      color: ${colors.lightGrayText};
      line-height: 16px;
    }
  }
`

export const StyledButton = styled.div`
  position: absolute;
  top: 240px;
  background: ${colors.coupGreen};
  width: 48px;
  height: 48px;
  left: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  pointer-events: auto;
  z-index: 0;
  text-align: center;
  font-size: 16px;
  border-radius: 0 4px 4px 0;

  span {
    color: ${colors.white};
    font-size: 11px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-left: 2px;
  }
`;
