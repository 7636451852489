import React from 'react';
import { withRouter } from 'react-router-dom';
import { Query } from 'react-apollo';
import { Empty } from 'antd';
import { map } from 'lodash';
import { LIST_ORDERS } from '../../graphql/orders';

import DealShowcase from '../../components/DealShowcase/DealShowcase';
import TransitionGrid from '../../components/TransitionGrid/TransitionGrid';
import Spinner from '../../components/Spinner/Spinner';

import { Container, StyledButton } from './styles';

import 'antd/es/empty/style/css';

const ListOrders = ({ input, history }) => {
  return (
    <Query query={LIST_ORDERS} variables={{ input }}>
      {({ loading, error, data: { orders } }) => {
        console.log('orders: ', orders);
        if (loading) return <Spinner />;
        if (error) return `Error! ${error.message}`;

        const { entries } = orders;
        const results = map(entries, ({ deal, ...rest }) => ({
          ...rest,
          ...deal,
          brand_image_url: deal.logoUrl,
          expiration_date: deal.generalExpirationDate
        }))

        const items = map(results, (deal) => (
          <DealShowcase {...deal} pathname={history.location.pathname} />
        ));

        return (
          <Container>
            {items.length ? (
              <TransitionGrid
                items={items}
                visible={true}
              />
            ) : (
              <Empty
                image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                description={
                  <span>
                    Looks like you haven't requested any deals...
                  </span>
                }
              >
                <StyledButton onClick={() => history.replace('/')}>
                  Get You Some
                </StyledButton>
              </Empty>
            )}
          </Container>
        );
      }}
    </Query>
  );
};

export default withRouter(ListOrders);
