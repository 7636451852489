import React from 'react';
import styled from "styled-components";

// const TestCard = ({ mutateAction, ...rest }) => {
//   return (
//     <OfferCardContainer onClick={mutateAction} {...rest}>
//       <LogoContainer>
//         <ImgHolder src="https://cdn.joinhoney.com/images/store-logos/7357363250899199020.png" />
//       </LogoContainer>
//       <InfoContainer>
//       </InfoContainer>
//     </OfferCardContainer>
//   );
// }

const LogoContainer = styled.div`
  width: 190px;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const InfoContainer = styled.div`
  width: 145px;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  vertical-align: top;
  justify-content: center;

  .name {
    color: #c0c0c0;
    display: block;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
  }
`;

const ImgHolder = styled.img`
  max-width: 84px;
  max-height: 84px;
`;

const CategoryOfferCard = ({ category, mutateAction }) => (<div onClick={mutateAction}>{category.name}</div>)

// const CategoryOfferCardStyled = styled(CategoryOfferCard)`
//   width: 20%;
//   height: 120px;
//   display: flex;
//   cursor: pointer;
//   overflow: hidden;
//   transition: all 0.3s linear;
//   margin-top: 10px;
//   margin-right: 20px;
//   box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.02);
//   border-width: 1px;
//   border-style: solid;
//   border-color: ${({ isFollowed }) => isFollowed ? '#00c57d' : 'rgba(0, 0, 0, 0.06)'}
//   border-radius: 3px;
//   background-color: #ffffff;
// `;

const CategoryOfferCardStyled = styled.div`
  height: 120px;
  display: flex;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'inherit'};
  opacity: ${props => (props.disabled ? 0.7 : 1)};
  overflow: hidden;
  transition: all 0.3s linear;
  margin-top: 10px;
  margin-right: 20px;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.02);
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ isFollowed }) => isFollowed ? '#00c57d' : 'rgba(0, 0, 0, 0.06)'};
  background-color: #ffffff;
  padding: 16px;
  align-items: center;
  justify-content: flex-start;
  transition: background-color .2s;
  font-size: 18px;

  &:hover {
    background-color: #fbfbfb;
  }

  .image-holder {
    margin-right: 24px;
    width: 40%;
    height: 90%;
    text-align: center;

    img {
      max-height: 100%;
      max-width: 100%;
      width: auto;
    }
  }
`;

const BrandOfferCardStyled = styled.div`
  height: 120px;
  display: flex;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'inherit'};
  opacity: ${props => (props.disabled ? 0.7 : 1)};
  overflow: hidden;
  transition: all 0.3s linear;
  margin-top: 10px;
  margin-right: 20px;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.02);
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ isFollowed }) => isFollowed ? '#00c57d' : 'rgba(0, 0, 0, 0.06)'};
  background-color: #ffffff;
  padding: 16px;
  align-items: center;
  justify-content: flex-start;
  transition: background-color .2s;
  font-size: 18px;

  &:hover {
    background-color: #fbfbfb;
  }

  img {
    width: 40%;
    height: 90%;
    margin-right: 24px;
    object-fit: contain;
  }
`;

const TextAreaStyled = styled.textarea`
  padding: 12px 18px 16px 18px;
  outline: none;
  box-shadow: 0 8px 19px 0 rgba(0, 0, 0, 0.15);
  border: 0px solid;
  border-radius: 6px;
  width: 350px;
`;

export const OfferCardStyled = props => {
  return props.entityType === 'category' ?
    <CategoryOfferCardStyled {...props}>{props.children}</CategoryOfferCardStyled>
    : <BrandOfferCardStyled {...props}>{props.children}</BrandOfferCardStyled>;
};
