import auth0 from 'auth0-js';
import config from './config';
import { LOGOUT_URI } from './config/environment';

class Auth {
  constructor() {
    const authConfig = { ...config.auth0 };

    this.auth0 = new auth0.WebAuth(authConfig);
    this.authFlag = 'isLoggedIn';
  }

  login = () => {
    this.auth0.authorize();
  };

  getIdToken() {
    return this.idToken;
  }

  handleAuthentication = () => {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) return reject(err);
        if (!authResult || !authResult.idToken) {
          return reject(err);
        }
        this.setSession(authResult);
        resolve();
      });
    });
  };

  setSession(authResult) {
    this.idToken = authResult.idToken;
    localStorage.setItem(this.authFlag, JSON.stringify(true));
  }

  logout = () => {
    localStorage.setItem(this.authFlag, JSON.stringify(false));
    this.auth0.logout({
      returnTo: LOGOUT_URI,
      clientID: 'i7v6zDgUO03QFdIXWARLY77DSWSqt19C',
    });
  };

  silentAuth() {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult) => {
        if (err) return reject(err);
        this.setSession(authResult);
        resolve();
      });
    });
  }

  isAuthenticated = () => {
    return JSON.parse(localStorage.getItem(this.authFlag));
  };
}

const auth = new Auth();

export default auth;
