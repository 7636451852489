import { REDIRECT_URI } from './config/environment';

const dev = {
  auth0: {
    domain: 'trycoup-dev.auth0.com',
    clientID: 'i7v6zDgUO03QFdIXWARLY77DSWSqt19C',
    redirectUri: REDIRECT_URI,
    responseType: 'token id_token'
  }
};

const prod = {
  auth0: {
    domain: 'trycoup.auth0.com',
    clientID: 'yjDpnNu9RV5MxwEK1Zy86HkSSsNIZwHr',
    redirectUri: REDIRECT_URI,
    responseType: 'token id_token'
  }
};

const config = process.env.REACT_APP_STAGE === 'production'
  ? prod
  : dev;

export default {
  ...config
};
