import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colors } from '../../styles';
import {
  Layout,
  Menu,
  Button
} from 'antd';

const { Header } = Layout;

export const Logo = styled.div`
  img {
    height: 36px;
    width: auto;
  }
`;

export const StyledHeader = styled(Header)`
  &.ant-layout-header {
    box-shadow: 0 8px 19px 0 rgba(0, 0, 0, 0.15);
    background: ${colors.white};
    height: 64px;
    line-height: 64px;
  }
`

export const StyledMenu = styled(Menu)`
  &&&& .ant-menu-item-selected {
    border-bottom: 2px solid ${colors.coupGreen};
    color: ${colors.coupGreen};
  }

  &&&& .ant-menu-item:hover {
    border-bottom: 2px solid ${colors.coupGreen};
    color: ${colors.coupGreen}
  }
`;

export const StyledMenuItem = styled(Menu.Item)`
  font-weight: 500;
  text-transform: uppercase;
`;

export const StyledButton = styled(Button)`
  &.ant-btn {
    padding-top: 10px;
    padding-bottom: 30px;
    border-radius: 5px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    background-color: ${colors.coupGreen};

    span {
      font-weight: 500;
      color: ${colors.white};
      text-transform: uppercase;
    }

    &&&&:hover {
      border-color: ${colors.coupGreen};

      span {
        color: ${colors.lightGrayText};
      }
    }
  }

`;

export const StyledLink = styled(Link)`
  font-size: 12px;
  text-transform: uppercase;
  color: ${colors.darkGrayText};
  letter-spacing: 2px;

  &&&&:hover {
    color: ${colors.coupGreen};
  }
`;
