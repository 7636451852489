import React from 'react'
import { Layout as AntdLayout } from 'antd';

import 'antd/es/layout/style/css';

const Content = ({ children }) => (
  <AntdLayout.Content style={{ height: '100%', minHeight: '100vh' }}>{children}</AntdLayout.Content>
);

export default Content
