import React from 'react'
import { Link, withRouter } from 'react-router-dom';
import { Menu, Row, Col, Button } from 'antd';
import { StyledHeader, StyledLink, Logo, StyledMenuItem, StyledMenu, StyledButton } from './styles';
import { coupLogoGreen } from '../../assets/images';

import auth from '../../Auth';

import 'antd/es/menu/style/css';
import 'antd/es/row/style/css';
import 'antd/es/col/style/css';

const Nav = ({ history, location: { pathname } }) => {
  const isAuthenticated = auth.isAuthenticated();

  const logout = () => {
    auth.logout();
    history.replace('/');
  };

  const getSelectedKeys = (pathname) => {
    if (pathname === '/profile') {
      return ['profile'];
    } else if (pathname === '/subscribe') {
      return ['brands'];
    } else {
      return ['deals'];
    }
  };

  return (
    <StyledHeader>
      <Row>
        <Col span={4}>
          <Logo>
            <Link to="/">
              <img src={coupLogoGreen} />
            </Link>
          </Logo>
        </Col>
        <Col span={isAuthenticated ? 8 : 6} offset={isAuthenticated ? 11 : 13}>
          <StyledMenu
            theme="light"
            mode="horizontal"
            style={{ lineHeight: '63px' }}
            defaultSelectedKeys={["deals"]}
            selectedKeys={getSelectedKeys(pathname)}
            >
            <StyledMenuItem key="deals">
              <StyledLink to="/">Deals</StyledLink>
            </StyledMenuItem>
            <StyledMenuItem key="brands">
              <StyledLink to="/subscribe">Brands</StyledLink>
            </StyledMenuItem>
            {isAuthenticated && (
              <StyledMenuItem key="profile">
                <StyledLink to="/profile">Profile</StyledLink>
              </StyledMenuItem>
            )}
          </StyledMenu>
        </Col>
        <Col span={1}>
          {isAuthenticated ? (
            <StyledButton onClick={() => logout()}>
              Log Out
            </StyledButton>
          ) : (
            <StyledButton onClick={() => auth.login()}>
              Log In
            </StyledButton>
          )}
        </Col>
      </Row>
    </StyledHeader>
  );
};

export default withRouter(Nav);
