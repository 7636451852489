import Geocode from "react-geocode";
import _ from "lodash";

// Geocode.setApiKey("AIzaSyBsPjhFy_jOWRu-vrVrtfNiPAv56d3PSg0");
Geocode.setApiKey("AIzaSyDs89tnVxaFUz-nwgQAqaww1RACqkx9KSs");

export const fromAddress = (address, cb) => {
  // Get latidude & longitude from address.
  Geocode.fromAddress(address).then(
    response => {
      cb(response);
    },
    error => {
      console.error(error);
    }
  );
};

export const fromLatLng = (lat, lng, cb) => {
  Geocode.fromLatLng(lat, lng).then(
    response => {
      cb(response);
    },
    error => {
      console.error(error);
    }
  );
};

/*
{
  status: "OK",
  results: [{
    address_components: [
      {long_name: "Concourse A", short_name: "Concourse A", types: ["point of interest", "airport"]},
      {long_name: "College Park", short_name: "College Park", types: ["locality", "political"]},
      {long_name: "Clayton County", short_name: "Clayton County", types: ["administrative_area_level_2", "political"]},
      {long_name: "Georgia", short_name: "GA", types: ["administrative_area_level_1", "political"]},
      {long_name: "United States", short_name: "US", types: ["country", "political"]},
      {long_name: "30337", short_name: "30337", types: ["postal_code"]}
    ],
    formatted_address: "",
    geometry: {
      bounds: {
        northeast: {lat: 33.6438156, lng: -84.43856590000001}
        southwest: {lat: 33.6372194, lng: -84.43977749999999}
      },
      location: {
        lat: 33.6409155,
        lng: -84.4392246
      },
      location_type: "ROOFTOP",
      viewport: {
        northeast: {lat: 33.6438156, lng: -84.43782271970849}
        southwest: {lat: 33.6372194, lng: -84.44052068029151}
      }
    },
    place_id: ""
    types: ["airport"]
  }]
}
*/
const containsAppropriateType = ({ types }, intendedType) => {
  return _.indexOf(types, intendedType) > -1;
}

export const findZipFromResponse = (resp) => {
  const addressComponents = _.get(resp, 'results[0].address_components');
  const zipComponent = _.find(addressComponents, (ac) => {
    return containsAppropriateType(ac, 'postal_code');
  });
  
  return _.get(zipComponent, 'short_name');
}
